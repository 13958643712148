import React, { useState } from "react"
import { Link } from "gatsby"
// import ReactPlayer from "react-player/lazy"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import HeaderLogo from "../components/headerLogo/headerLogo"
// import HeaderMenu from "../components/headerMenu/headerMenu"
// import HeaderClose from "../components/headerClose/headerClose"
import LogoSticky from "../components/logoSticky/logoSticky"
import SliderHome from "../components/sliderHome/sliderHome"
import FeedInstagram from "../components/feedInstagram/feedInstagram"
import Video from "../components/video/video"

import styles from "./index.module.scss"

import ImageCategory01 from "../images/ji-won-choi-lookbook-919.jpg"
import ImageCategory02 from "../images/ji-won-choi-lookbook-999.jpg"
// import ImageVideoCover from "../images/JWC_origins-cover.jpg"
// import FileVideo from "../assets/jivideomusica_compressed.mp4"

function PageHome() {

  const CategoriesData = useStaticQuery(graphql`
    {
      allWordpressPage(filter: {wordpress_id: {eq: 367}}) {
        edges {
          node {
            acf {
              shop_category {
                category_1 {
                  source_url
                }
                category_2 {
                  source_url
                }
              }
            }
          }
        }
      }
    }
  `)

  const ACFpath = CategoriesData.allWordpressPage.edges[0].node.acf
  const Category01ImageUrl = ACFpath.shop_category.category_1.source_url
  const Category02ImageUrl = ACFpath.shop_category.category_2.source_url

  // console.log(CategoriesData)

  return (
    <Layout>
      <SEO title="Home" />

      <LogoSticky />
      <SliderHome />

      <section className={'marginSection paddingSection'}>
        <Video />
      </section>

      <section className={'marginSection ' + styles.sectionCategoriesHome}>
        <div className={'flex ' + styles.wrapper}>
          <div className={'col col-x2'}>
            <Link to="/shop">
              <div className={'bg bg-img ta-r ' + styles.category} style={{ backgroundImage: "url(" + Category01ImageUrl + ")" }}>
                <h3 className={styles.title}>clothing</h3>
              </div>
            </Link>
          </div>
          <div className={'col col-x2'}>
            <Link to="/shop">
              <div className={'bg bg-img ta-l ' + styles.category} style={{ backgroundImage: "url(" + Category02ImageUrl + ")" }}>
                <h3 className={styles.title}>accessories</h3>
              </div>
            </Link>
          </div>
        </div>
      </section>

      <section className={'marginSection paddingSection'}>
        <div className={'flex center ta-c'}>
          <div className={'col col-9 col-s-11'}>
            <FeedInstagram />
          </div>
        </div>
      </section>

    </Layout>
  )
}
export default PageHome
