import React from "react"
import { useStaticQuery, graphql } from "gatsby"

// import styles from "./video.module.scss"

function Video(props) {
    //console.log(props)

    const VideoData = useStaticQuery(graphql`
    {
      allWordpressPage(filter: {wordpress_id: {eq: 367}}) {
        edges {
          node {
            acf {
              video {
                video_text
                video_file {
                  source_url
                }
              }
            }
          }
        }
      }
    }
  `)
    const ACFpath = VideoData.allWordpressPage.edges[0].node.acf
    const VideoUrl = ACFpath.video.video_file.source_url
    const VideoText = ACFpath.video.video_text

    return (
        <>
            <div className={'flex center ta-c'}>
                <div className={'col col-6 col-s-8'}>
                    {/* <img src={ImageVideoCover} style={{ width: "100%" }}></img> */}
                    <video src={VideoUrl} style={{ width: "100%" }} controls></video>
                    <p>{VideoText}</p>
                </div>
            </div>
        </>
    )
}
export default Video
