import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import styles from "./logoSticky.module.scss"
import logoJWC from "../../images/JWC_logo--black.svg"

function HeaderLogo() {
  return (
    <>
      <Link to="/" className={styles.logoSticky}>
        <img src={logoJWC} alt={"Ji Won Choi - logo"} />
      </Link>
    </>
  )
}
HeaderLogo.propTypes = {
  siteTitle: PropTypes.string,
}

HeaderLogo.defaultProps = {
  siteTitle: ``,
}
export default HeaderLogo
