import React, { useCallback } from "react";
import { useStaticQuery, graphql } from "gatsby"

import styles from "./sliderHome.scss"

// Swiper import
import Swiper from "react-id-swiper"
import "swiper/css/swiper.css"

function SliderHome(props) {

  const params = {
    containerClass: "sliderHome",
    slidesPerView: 1,
    spaceBetween: 0,
    grabCursor: true,
    observer: true,
    observeParents: true
  }

  const data = useStaticQuery(graphql`
    {
      allWordpressPage(filter: {wordpress_id: {eq: 367}}) {
        edges {
          node {
            acf {
              slider_page {
                image_1 {
                  source_url
                }
                image_2 {
                  source_url
                }
              }
            }
          }
        }
      }
    }
  `)

  const renderItem = useCallback(
    // ({ idx, content }) => <div content={content} key={`slide_${idx}`} />,
    ({ image_1, image_2 }, index) =>
      <div key={index++}>
        <div className={styles.slideHalf + ' bg-img'} style={{ backgroundImage: "url(" + image_1.source_url + ")" }}></div>
        <div className={styles.slideHalf + ' bg-img'} style={{ backgroundImage: "url(" + image_2.source_url + ")" }}></div>
      </div>,
    []
  );

  const slides = data.allWordpressPage.edges[0].node.acf.slider_page.map(renderItem);
  // console.log(slides)

  return (
    <>
      <Swiper {...params}>

        {slides}

      </Swiper>
    </>
  )
}
export default SliderHome
