import React from "react"
import { useStaticQuery, graphql } from "gatsby"
//import { Link } from "gatsby"
//import PropTypes from "prop-types"

import styles from "./feedInstagram.module.scss"

import IconInstagram from "../../images/instagram.svg"
// import ImageIg01 from "../../images/ig/01.jpg"
// import ImageIg02 from "../../images/ig/02.jpg"
// import ImageIg03 from "../../images/ig/03.jpg"
// import ImageIg04 from "../../images/ig/04.jpg"
// import ImageIg05 from "../../images/ig/05.jpg"
// import ImageIg06 from "../../images/ig/06.jpg"
// import ImageIg07 from "../../images/ig/07.jpg"
// import ImageIg08 from "../../images/ig/08.jpg"

function FeedInstagram() {

  const FeedInstagramData = useStaticQuery(graphql`
    {
      allWordpressPage(filter: {wordpress_id: {eq: 367}}) {
        edges {
          node {
            acf {
              instagram_feed {
                image {
                  source_url
                }
              }
            }
          }
        }
      }
    }
  `)

  const ACFpath = FeedInstagramData.allWordpressPage.edges[0].node.acf
  // debug
  // console.log(ACFpath.instagram_feed)

  return (
    <>
      <div id={styles.feed}>
        <a href="https://www.instagram.com/ji.won.choi/" target="_blank">
          <img className={styles.iconInstagram} src={IconInstagram} alt={"Ji Won Choi - Instagram"} />
        </a>
        <div className={'flex'}>

          {ACFpath.instagram_feed?.map((post, i) => (
            <div className={'col col-x8 col-m-x4 ' + styles.post} key={i}>
              <a href="https://www.instagram.com/ji.won.choi/" target="_blank">
                <div className={'square bg_green bg bg-img'} style={{ backgroundImage: "url(" + post.image.source_url + ")" }}></div>
              </a>
            </div>
          ))}

        </div>
      </div>
    </>
  )
}
export default FeedInstagram
